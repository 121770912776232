<template>
  <div>
    <div id="container" v-if="!active && status == ''">
      <h1 class="text">
        TOPLANTI BİLGİLERİ KONTROL EDİLİYOR
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </h1>
    </div>
    <div id="container" v-if="!active">
      <h1 class="text">{{ message }}</h1>
    </div>
    <vue-jitsi-meet
      v-if="active"
      style="height: 800px"
      ref="jitsiRef"
      domain="live.terapivitrini.com"
      :options="jitsiOptions"
    ></vue-jitsi-meet>
  </div>
</template>
  <style scoped>
#container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 500px;
}
.text {
  color: #000;
  padding: 20px;
  text-align: center;
}
</style>
  <script>
import { JitsiMeet } from "@mycure/vue-jitsi-meet";
export default {
  metaInfo() {
    return {
      title: "Terapi Vitrini - Online Görüşme",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Terapi Vitrini Online Görüşme - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
  },
  components: {
    VueJitsiMeet: JitsiMeet,
  },
  computed: {
    jitsiOptions() {
      return {
        appId: "vpaas-magic-cookie-a36217c8bbfd43d28cbd75093a31c9eb",
        roomName: this.roomName,
        noSSL: false,
        userInfo: {
          email: this.email,
          displayName: this.username,
        },
        configOverwrite: {
          enableNoisyMicDetection: true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: true,
          SHOW_WATERMARK_FOR_GUESTS: true,
          SHOW_CHROME_EXTENSION_BANNER: true,
        },
        onload: this.onIFrameLoad,
      };
    },
  },
  data() {
    return {
      active: false,
      roomName: "",
      status: "",
      username: "",
      email: "",
      message: "",
    };
  },
  methods: {},
  mounted() {
    setTimeout(() => {
      let info = {
        id: this.$route.params.id,
      };
      if (this.$store.state.isLogin) {
        this.$store
          .dispatch("educationVideoCallingGetInfo", info)
          .then((value) => {
            this.status = value.type;
            if (value.type == "success") {
              this.roomName = value.data.roomName;
              this.username = value.data.username;
              this.email = value.data.email;
              this.active = true;
            }
            if (value.type == "error") {
              if (value.message == "ERRxMRPE") {
                this.message =
                  "ÇOK FAZLA SMS TALEP EDİLDİ. BİRAZ BEKLEYİP YENİDEN DENEYİN";
              }
              if (value.message == "ERRxCNW") {
                this.message = "TOPLANTIYA ŞUAN ERİŞİLEMEZ";
              }
              if (value.message == "ERRxCLS") {
                this.message = "TOPLANTI BULUNAMADI";
              }
            }
          });
      } else {
        /**LOGİN SAYFASINA YÖNLENDİR */
      }
    }, 2000);
  },
};
</script>